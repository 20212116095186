import * as FormikFields from 'client/components/FormikFields';
import Col from 'client/components/Col';
import FormRow from 'client/components/FormRow';
import PhoneNumberField from 'client/components/PhoneNumberField';
import React from 'react';
import { Box, Button } from '@indeed/ifl-components';
import { EmergencyContactTaskTrackingEvents } from 'client/pages/emergencycontact/EmergencyContact.tracking';
import { EmergencyFormValues } from 'client/pages/emergencycontact/components/Form/formConstants';
import { FormikProps } from 'formik';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

export interface FormProps {
    formik: FormikProps<EmergencyFormValues>;
    baseUrl: string;
}

const Form = ({ formik, baseUrl }: FormProps): JSX.Element => {
    const history = useHistory();

    const onAccept = (): void => {
        datadogRum.addAction(EmergencyContactTaskTrackingEvents.submitBtnClicked);
        history.push({ pathname: `${baseUrl}/confirm`, search: window.location.search });
    };

    return (
        <>
            <Box>
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'name',
                                required: true,
                                className: 'dd-privacy-hidden',
                            }}
                            label="Full name"
                            name="name"
                        />
                    </Col>
                </FormRow>
                <FormRow sx={{ marginBlock: 0 }}>
                    <Col>
                        <PhoneNumberField
                            marginBlock={0}
                            formik={formik}
                            label="Phone number"
                            required
                            name="phone"
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'off',
                                required: true,
                                className: 'dd-privacy-hidden',
                            }}
                            label="Relation to you"
                            name="relationship"
                        />
                    </Col>
                </FormRow>
            </Box>
            <FormRow>
                <Col>
                    <Button
                        full
                        onClick={onAccept}
                        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                        type="button"
                        loading={formik.isSubmitting}
                        sx={{ marginBlockStart: 4 }}
                    >
                        Submit
                    </Button>
                </Col>
            </FormRow>
        </>
    );
};

export default Form;

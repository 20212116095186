import ConfirmAddressEntry from 'client/components/ConfirmAddressEntry';
import ConfirmEntry from 'client/components/ConfirmEntry';
import React from 'react';
import { Button, Flex } from '@indeed/ifl-components';
import { FormikProps } from 'formik';
import { ProfileFormValues } from 'client/pages/profile/components/Form/formConstants';
import { ProfileTaskTrackingEvents } from 'client/pages/profile/Profile.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

interface ConfirmAddressProps {
    data: ProfileFormValues;
    formik: FormikProps<ProfileFormValues>;
    dirty: boolean;
    isSubmitting: boolean;
    baseUrl: string;
}

const ConfirmAddress = ({
    data,
    formik,
    dirty,
    isSubmitting,
    baseUrl,
}: ConfirmAddressProps): JSX.Element => {
    const history = useHistory();
    const onAcceptExistingAddress = (): void => {
        datadogRum.addAction(ProfileTaskTrackingEvents.keepOriginalAddressClicked);
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    };
    const onAcceptSuggestedAddress = async (): Promise<void> => {
        try {
            datadogRum.addAction(ProfileTaskTrackingEvents.useGoogleFormattedAddressClicked);
            await formik.setFieldValue('address1', data.suggestedAddress1);
            if (data.suggestedAddress2) {
                await formik.setFieldValue('address2', data.suggestedAddress2);
            }
            await formik.setFieldValue('city', data.suggestedCity);
            await formik.setFieldValue('postalCode', data.suggestedPostalCode);
            await formik.setFieldValue('state', data.suggestedState);
        } catch (err) {
            /* eslint-disable-next-line no-console */
            console.log(err);
        }
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    };

    if (!dirty) {
        // loaded page directly, needs to start from page one
        history.push({
            pathname: baseUrl,
            search: window.location.search,
        });
    }
    // if for whatever reason we don't have suggestedAddressData, move directly to final profile confirm page
    if (
        !(
            data.suggestedAddress1 ||
            data.suggestedCity ||
            data.suggestedState ||
            data.suggestedPostalCode
        )
    ) {
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    }
    const values = {
        originalAddress: (
            <ConfirmAddressEntry
                address1={data.address1}
                address2={data.address2}
                city={data.city}
                state={data.state}
                postalCode={data.postalCode}
            />
        ),
        suggestedAddress: (
            <ConfirmAddressEntry
                address1={data.suggestedAddress1!}
                address2={data.suggestedAddress2!}
                city={data.suggestedCity!}
                state={data.suggestedState!}
                postalCode={data.suggestedPostalCode!}
            />
        ),
    };

    return (
        <>
            <ConfirmEntry title="Entered address">{values.originalAddress}</ConfirmEntry>
            <ConfirmEntry title="Suggested address">{values.suggestedAddress}</ConfirmEntry>
            <Flex sx={{ flexDirection: 'column', gap: 3, marginTop: 4 }}>
                <Button
                    full
                    sx={{ marginBlockStart: 4 }}
                    size="sm"
                    type="button"
                    loading={isSubmitting}
                    onClick={onAcceptSuggestedAddress}
                >
                    Use suggested address
                </Button>
                <Button
                    full
                    variant="secondary"
                    size="sm"
                    type="button"
                    loading={isSubmitting}
                    onClick={onAcceptExistingAddress}
                >
                    Keep original address
                </Button>
            </Flex>
        </>
    );
};

export default ConfirmAddress;

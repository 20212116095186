import * as FormikFields from 'client/components/FormikFields';
import BackButton from 'client/components/BackButton';
import Col from 'client/components/Col';
import FormRow from 'client/components/FormRow';
import React from 'react';
import useCompleteUkDocuments from 'client/hooks/useCompleteUkDocuments';
import { Box, Button, Heading } from '@indeed/ifl-components';
import { Form, Formik } from 'formik';
import {
    OverseasFormValues,
    getInitialOverseasFormValues,
    overseasFormValidationSchema,
    toCompleteUkDocumentsInput,
} from 'client/pages/ukdocuments/components/FormUtils/formConstants';
import { UkDocumentsTaskTrackingEvents } from 'client/pages/ukdocuments/UkDocuments.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';
import { yesNoOptions } from 'client/utils/options';

export interface OverseasFormProps {
    task: ComplianceTask;
}

const OverseasForm = ({ task }: OverseasFormProps): JSX.Element => {
    const history = useHistory();
    const isCompleted = task.status === 'COMPLETED';
    const [callCompleteUkDocuments] = useCompleteUkDocuments(
        task,
        `/uk-documents/${task.slug}`,
        'UkDocumentsOverseasForm'
    );

    const onSubmit = async (values: OverseasFormValues): Promise<void> => {
        datadogRum.addAction(UkDocumentsTaskTrackingEvents.overseasFormSubmitBtnClicked);
        const input = toCompleteUkDocumentsInput(task.slug, values);
        await callCompleteUkDocuments(input);
    };

    return (
        <Box>
            <BackButton onClick={() => history.push(`/uk-documents/${task.slug}`)} />
            <Heading level={4}>Overseas Questionnaire</Heading>
            <Box sx={{ marginBlockStart: 4 }}>
                <Formik
                    initialValues={getInitialOverseasFormValues(task)}
                    validationSchema={overseasFormValidationSchema}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                >
                    {(formik) => (
                        <Form>
                            <FormRow>
                                <Col>
                                    <Heading level={3}>
                                        Have you ever worked or lived abroad for 3 months in the
                                        last 5 years?
                                    </Heading>
                                    <p>
                                        If YES, it may be necessary to get an overseas Police Check
                                    </p>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <FormikFields.RadioField
                                        name="livedOverseas"
                                        items={yesNoOptions}
                                        radioProps={{
                                            showRequiredIndicator: true,
                                            className: 'dd-privacy-hidden',
                                            disabled: isCompleted,
                                        }}
                                    />
                                </Col>
                            </FormRow>
                            {!isCompleted && (
                                <FormRow>
                                    <Col>
                                        <Button
                                            type={'submit'}
                                            loading={formik.isSubmitting}
                                            disabled={!formik.isValid || formik.isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </FormRow>
                            )}
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default OverseasForm;

import { datadogRum } from '@datadog/browser-rum';
import { wcwLocalPreferences } from 'client/WebappLocalPreferences';

/**
 * Reports an error to DataDog
 *
 * @param {any} error The error to report
 * @param {string} scopeKey The scope extra key
 * @param {any} scopeValue The scope extra value
 */
export const reportErrorToThirdPartyTools = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    scopeKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scopeValue: any
): void => {
    if (wcwLocalPreferences.consoleLogSentryErrors) {
        /* eslint-disable-next-line no-console */
        console.log('reportErrorToThirdPartyTools', {
            error,
            scopeKey,
            scopeValue,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ddContext: Record<string, any> = {
        [scopeKey]: scopeValue,
    };
    datadogRum.addError(error, ddContext);
};

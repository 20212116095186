import React, { PropsWithChildren } from 'react';
import createCache, { EmotionCache } from '@emotion/cache';
import { ApolloProvider } from '@apollo/client';
import { GlobalStyles, IndeedThemeProvider } from '@indeed/ifl-components';
import { StyleProps } from '@indeed/ifl-components/src/utils/styled';
import { datadogRum } from '@datadog/browser-rum';
import { flex } from '@indeed/flex-ifl-theme';

import ErrorBoundary from 'client/components/ErrorBoundary/ErrorBoundary';
import { AuthenticationProvider } from 'client/contexts/AuthenticationContext';
import { ClientConfig, ClientConfigProvider } from 'client/contexts/ClientConfigContext';
import { FeatureFlagProvider } from 'client/contexts/FeatureFlagContext';
import { InitialStateType } from 'server/controllers/common';
import { LocalQueryClientProvider } from 'client/contexts/LocalQueryClientContext';
import { ToastProvider } from 'client/contexts/ToastContext';
import { createClient } from 'client/utils/apollo';
import { initDatadogRum } from 'client/utils/datadog';

export const getInitialState = (): InitialStateType => {
    const initialState: InitialStateType = window.__initialState || {};
    // Allow the passed state to be garbage-collected
    delete window.__initialState;
    return initialState;
};

type ThirdPartyServicesType = {
    emotionCache: EmotionCache;
};

export const initThirdPartyServices = (initialState: InitialStateType): ThirdPartyServicesType => {
    initDatadogRum(initialState.config, initialState.tkData);
    const emotionCache = createCache({ key: 'css' });

    return { emotionCache };
};

const onError = (error: Error, componentStack: string, level: string): void => {
    if (level === 'local' && process.env.JEST_WORKER_ID === undefined) {
        /* eslint-disable-next-line no-console */
        console.log(error, `\nComponent Stack\n${componentStack}`);
    } else if (process.env.JEST_WORKER_ID === undefined) {
        datadogRum.addError(error);
    }
};

interface BaseAppProvidersProps extends StyleProps {
    config: ClientConfig;
    expectingRoles: Roles[];
    loginType: LoginType;
    injectedGraphQlVariables?: string[];
}

/**
 * Wrap some standard providers for every app
 */
export const BaseAppProviders = ({
    config,
    expectingRoles,
    loginType,
    injectedGraphQlVariables = [],
    sx,
    children,
}: PropsWithChildren<BaseAppProvidersProps>): JSX.Element => {
    return (
        // @ts-ignore
        <IndeedThemeProvider theme={flex}>
            <GlobalStyles
                sx={{
                    /* eslint-disable @typescript-eslint/naming-convention */
                    '#app-root': { display: 'flex', flexDirection: 'column' },
                    '#app-root, html, body': { blockSize: '100%' },
                    a: { textDecoration: 'none' },
                    ...sx,
                    /* eslint-enable @typescript-eslint/naming-convention */
                }}
            />
            <ErrorBoundary
                config={config}
                onError={(error: Error, componentStack: string) =>
                    onError(error, componentStack, config.level!)
                }
            >
                <ToastProvider>
                    <ClientConfigProvider clientConfig={config}>
                        <LocalQueryClientProvider>
                            <AuthenticationProvider
                                expectingRoles={expectingRoles}
                                loginType={loginType}
                            >
                                {({ user }) => {
                                    if (!user) {
                                        // only happens on page load because react will process this children function
                                        // before calling the AuthenticationProvider, functionally not a concern
                                        return null;
                                    }

                                    const accountKey = user!.accountKey!;
                                    const apolloClient = createClient(
                                        config.backendUrl!,
                                        accountKey,
                                        injectedGraphQlVariables
                                    );
                                    return (
                                        // Adding 2nd ErrorBoundary here where we have access to AuthenticationContext so we can tag with user.accountKey
                                        <ErrorBoundary
                                            config={config}
                                            onError={(error: Error) =>
                                                datadogRum.addError(error, {
                                                    accountKey: accountKey,
                                                })
                                            }
                                        >
                                            <ApolloProvider client={apolloClient}>
                                                <FeatureFlagProvider>
                                                    {children}
                                                </FeatureFlagProvider>
                                            </ApolloProvider>
                                        </ErrorBoundary>
                                    );
                                }}
                            </AuthenticationProvider>
                        </LocalQueryClientProvider>
                    </ClientConfigProvider>
                </ToastProvider>
            </ErrorBoundary>
        </IndeedThemeProvider>
    );
};

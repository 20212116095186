import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@indeed/ifl-components';
import { SystemStyleObject } from '@indeed/ifl-css/src/types';

export interface ContainerProps extends PropsWithChildren<Omit<BoxProps, 'as'>> {
    // if true, extend container to full size on small screens
    full?: boolean;
}

const Container = ({ full = false, sx = {}, ...rest }: ContainerProps): JSX.Element => {
    const fullSx = full ? { paddingInline: [0, 4] } : {};
    const finalSx = {
        paddingInline: 4,
        marginInline: 'auto',
        inlineSize: '100%',
        // loosely based off of bootstrap container compared to IFL's break points
        maxInlineSize: [null, '34rem', '46rem', '60rem', '76rem'],
        ...fullSx,
        ...sx,
    } as SystemStyleObject;

    return <Box sx={finalSx} {...rest} />;
};

export default Container;

import React from 'react';
import { Button, Heading } from '@indeed/ifl-components';

import ButtonLink from 'client/components/ButtonLink';
import StatusTag from 'client/components/StatusTag';
import { findTaskByType } from 'client/utils/task';
import { useRequiredTaskUrl } from './useRequiredTaskUrl';
import { useTasks } from 'client/contexts/TasksContext';

interface RequiresTaskPageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
    requiredTaskType: TaskType;
    requiredTaskName: string;
    requiredTaskPath: string;
}

const RequiresTaskPageContent = ({
    heading,
    task,
    taskName,
    requiredTaskType,
    requiredTaskName,
    requiredTaskPath,
}: RequiresTaskPageContentProps): JSX.Element => {
    const { tasks } = useTasks();
    const requiredTask = findTaskByType(tasks, requiredTaskType);
    const navigateToHandler = useRequiredTaskUrl(requiredTask!);

    return (
        <>
            {heading && <Heading level={4}>{heading}</Heading>}
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>
                You must complete the <strong>{requiredTaskName}</strong> step before proceeding
                with the {taskName}.
            </p>
            <ButtonLink
                to={navigateToHandler ? '/' : `/${requiredTaskPath}/${requiredTask!.slug}`}
                onClick={navigateToHandler}
            >
                <Button>Go to {requiredTaskName}</Button>
            </ButtonLink>
        </>
    );
};

interface RequiresCjoPageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresCjoPageContent = ({
    task,
    heading,
    taskName,
}: RequiresCjoPageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="CONDITIONAL_JOB_OFFER"
            requiredTaskPath="conditional-job-offer"
            requiredTaskName="conditional job offer"
        />
    );
};

interface RequiresBgcPageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresBgcPageContent = ({
    task,
    heading,
    taskName,
}: RequiresBgcPageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="BACKGROUND_CHECK"
            requiredTaskPath="background-check"
            requiredTaskName="background check"
        />
    );
};

interface RequiresProfilePageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresProfilePageContent = ({
    task,
    heading,
    taskName,
}: RequiresProfilePageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="PROFILE"
            requiredTaskPath="profile"
            requiredTaskName="profile"
        />
    );
};

interface RequiresBankDetailsPageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresBankDetailsPageContent = ({
    task,
    heading,
    taskName,
}: RequiresBankDetailsPageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="BANK_DETAILS"
            requiredTaskPath="bank-details"
            requiredTaskName="Bank Details"
        />
    );
};

interface RequiresFormI9PageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresFormI9PageContent = ({
    task,
    heading,
    taskName,
}: RequiresFormI9PageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="FORM_I9"
            requiredTaskPath="i-9"
            requiredTaskName="Form I-9"
        />
    );
};

interface RequiresAdditionalDocumentsPageContentProps {
    heading?: string;
    task: ComplianceTask;
    taskName: string;
}

export const RequiresAdditionalDocumentsPageContent = ({
    task,
    heading,
    taskName,
}: RequiresAdditionalDocumentsPageContentProps): JSX.Element => {
    return (
        <RequiresTaskPageContent
            task={task}
            heading={heading}
            taskName={taskName}
            requiredTaskType="LOCATION_REQUIREMENTS"
            requiredTaskPath="additional-documents"
            requiredTaskName="Additional Documents"
        />
    );
};

export default RequiresTaskPageContent;

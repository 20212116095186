import Col from 'client/components/Col';
import React from 'react';
import { Box, Button } from '@indeed/ifl-components';
import { FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import { BackgroundCheckFormValues } from './formConstants';
import { getGenderOptions, yesNoOptions } from 'client/utils/options';

export interface FormProps {
    formik: FormikProps<BackgroundCheckFormValues>;
    baseUrl: string;
    alreadySuppliedSsn: boolean;
    alreadySuppliedGender: boolean;
}

const Form = ({
    formik,
    baseUrl,
    alreadySuppliedSsn,
    alreadySuppliedGender,
}: FormProps): JSX.Element => {
    const history = useHistory();
    const genderOptions = getGenderOptions();

    const preventClipboardDefault = (event: React.ClipboardEvent): void => {
        event.preventDefault();
    };

    const onAccept = (): void => {
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    };

    return (
        <Box>
            {!alreadySuppliedSsn && (
                <>
                    <FormRow>
                        <Col>
                            <FormikFields.MaskedTextField
                                label="Social security number"
                                inputProps={{
                                    autoComplete: 'off',
                                    maxLength: 11,
                                    required: true,
                                    className: 'dd-privacy-hidden',
                                }}
                                aria-valuemax={9}
                                name="ssn"
                                mask="999999999"
                                hideOnBlur
                                removeCharRegex="-"
                            />
                        </Col>
                    </FormRow>
                    <FormRow>
                        <Col>
                            <FormikFields.MaskedTextField
                                label="Re-enter social security number"
                                inputProps={{
                                    autoComplete: 'off',
                                    maxLength: 11,
                                    required: true,
                                    onPaste: preventClipboardDefault,
                                    onCopy: preventClipboardDefault,
                                    onCut: preventClipboardDefault,
                                    className: 'dd-privacy-hidden',
                                }}
                                aria-valuemax={9}
                                name="ssnReenter"
                                mask="999999999"
                                hideOnBlur
                                removeCharRegex="-"
                            />
                        </Col>
                    </FormRow>
                </>
            )}
            {!alreadySuppliedGender && (
                <FormRow>
                    <Col>
                        <FormikFields.RadioField
                            label="Gender"
                            name="gender"
                            items={genderOptions}
                            radioProps={{
                                showRequiredIndicator: true,
                                className: 'dd-privacy-hidden',
                            }}
                        />
                    </Col>
                </FormRow>
            )}
            <FormRow>
                <Col>
                    <FormikFields.RadioField
                        label="Were you born in Puerto Rico?"
                        name="bornInPuertoRico"
                        items={yesNoOptions}
                        radioProps={{
                            showRequiredIndicator: true,
                            onChange: (event) => {
                                const value = (event.target as HTMLInputElement).value;
                                /* eslint-disable @typescript-eslint/no-floating-promises */
                                if (value === 'false') {
                                    formik.setFieldValue('mothersMaidenName', undefined);
                                }
                                formik.setFieldValue('bornInPuertoRico', value);
                                /* eslint-enable @typescript-eslint/no-floating-promises */
                            },
                            className: 'dd-privacy-hidden',
                        }}
                    />
                </Col>
            </FormRow>
            {formik.values.bornInPuertoRico === 'true' && (
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'off',
                                required: true,
                                className: 'dd-privacy-hidden',
                            }}
                            label="What is your mother's maiden name?"
                            name="mothersMaidenName"
                        />
                    </Col>
                </FormRow>
            )}
            <FormRow>
                <Col>
                    <Button
                        onClick={onAccept}
                        disabled={!formik.isValid}
                        type="button"
                        full
                        sx={{ marginBlockStart: 4 }}
                    >
                        Accept and continue
                    </Button>
                </Col>
            </FormRow>
        </Box>
    );
};

export default Form;

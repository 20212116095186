import * as FormikFields from 'client/components/FormikFields';
import ButtonsCard from 'client/pages/bgc/components/ButtonsCard';
import Col from 'client/components/Col';
import ConfirmLegalNameModal from 'client/components/ConfirmLegalNameModal';
import FormRow from 'client/components/FormRow';
import React, { useState } from 'react';
import dayjs from 'client/utils/dayjs';
import { Alert, Button, Text } from '@indeed/ifl-components';
import { Form, Formik, FormikProps } from 'formik';
import { getFullName, getWorkerFullName } from 'client/utils/worker';
import { useWorker } from 'client/contexts/WorkerContext';

export type SignAndAcknowledgeValues = {
    firstName: string;
    lastName: string;
    currentDate: string;
    agree: boolean[];
};

const typeNameAcknowledgementLabel = (
    <>
        I agree to use an electronic signature (e-signature) in lieu of a hand-written signature and
        be bound as though I signed these documents in writing by hand.
        <br />
        <br /> By typing my name and pressing "Sign and acknowledge", I acknowledge and agree to the
        terms of this document.
    </>
);
const clickAcknowledgementLabel =
    'By checking the box and pressing "Sign and acknowledge", I acknowledge and agree to the terms of this document.';
const clickDeclineLabel =
    'I understand that by checking the box and pressing "Decline and continue", I am revoking my agreement to the terms of this document.';

type SignBlockProps = {
    showNameFields: boolean;
    isAcknowledge: boolean;
    isMedia: boolean;
    formik: FormikProps<SignAndAcknowledgeValues>;
};

/**
 * Component which only shows fields for signing or acknowledging a document
 */
const SignBlock = ({
    showNameFields,
    isAcknowledge,
    isMedia,
    formik,
}: SignBlockProps): JSX.Element => {
    // For non-acknowledge docs, disable the sign button until they have checked the agreement
    // and filled out there name if necessary
    const disableSignButton =
        !isAcknowledge &&
        (!formik.values.agree[0] ||
            (showNameFields && (!formik.values.firstName || !formik.values.lastName)));

    return (
        <>
            {showNameFields && (
                <>
                    <FormRow sx={{ maxWidth: ['auto', '24rem'] }}>
                        <Col>
                            <FormikFields.TextField
                                label="Legal first name"
                                name="firstName"
                                inputProps={{
                                    required: true,
                                    autoComplete: 'given-name',
                                }}
                            />
                        </Col>
                    </FormRow>
                    <FormRow sx={{ maxWidth: ['auto', '24rem'] }}>
                        <Col>
                            <FormikFields.TextField
                                label="Legal last name"
                                name="lastName"
                                inputProps={{
                                    required: true,
                                    autoComplete: 'family-name',
                                }}
                            />
                        </Col>
                    </FormRow>
                </>
            )}
            {!isMedia && (
                <FormRow sx={{ maxWidth: ['auto', '24rem'] }}>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'off',
                                disabled: true,
                                className: 'dd-privacy-hidden',
                            }}
                            name="currentDate"
                            label="Date"
                        />
                    </Col>
                </FormRow>
            )}
            {!isAcknowledge && !isMedia && (
                <FormRow>
                    <Col>
                        <FormikFields.CheckboxField
                            name="agree"
                            items={[
                                {
                                    label: showNameFields
                                        ? typeNameAcknowledgementLabel
                                        : clickAcknowledgementLabel,
                                    value: 'true',
                                    required: true,
                                },
                            ]}
                        />
                    </Col>
                </FormRow>
            )}
            <FormRow>
                <Col>
                    <ButtonsCard>
                        {isMedia ? (
                            <Button type="submit" sx={{ width: ['100%', 'auto'] }}>
                                Next
                            </Button>
                        ) : (
                            <Button
                                type={'submit'}
                                disabled={disableSignButton}
                                sx={{ width: ['100%', 'auto'] }}
                            >
                                {isAcknowledge
                                    ? 'Acknowledge and continue'
                                    : 'Sign and acknowledge'}
                            </Button>
                        )}
                    </ButtonsCard>
                </Col>
            </FormRow>
        </>
    );
};

type SignOrDeclineBlockProps = {
    onDecline?: () => void;
    showNameFields: boolean;
    existingWorkerDocument: TaskWorkerDocumentType | null;
    isToggleable: boolean;
    formik: FormikProps<SignAndAcknowledgeValues>;
};

/**
 * Component which incorporates the SignBlock component and adds fields and informational text for
 * declining a document.
 */
const SignOrDeclineBlock = ({
    onDecline,
    showNameFields,
    existingWorkerDocument,
    isToggleable,
    formik,
}: SignOrDeclineBlockProps): JSX.Element => {
    const showSignBlock =
        !isToggleable || !existingWorkerDocument || existingWorkerDocument.action === 'DECLINED';
    const showDeclineBlock =
        !isToggleable || !existingWorkerDocument || existingWorkerDocument.action === 'SIGNED';

    const getLabelText = (): string => {
        if (!existingWorkerDocument) {
            if (isToggleable) {
                return 'this form is optional and can be declined. You will be able to come back later and change your election if you wish.';
            } else {
                return 'this form is optional and can be declined.';
            }
        } else if (existingWorkerDocument.action === 'DECLINED') {
            return 'you previously declined this document but may choose to sign it if you wish.';
        } else {
            return 'you previously signed this document but may choose to decline it if you wish.';
        }
    };

    // If the sign block is present, the `agree` value represents agreeing to sign. Otherwise,
    // the user needs to check the box to agree to revoke their signature and decline
    const disableDeclineButton = showSignBlock ? !!formik.values.agree[0] : !formik.values.agree[0];

    return (
        <>
            <FormRow>
                <Col>
                    <Alert status="info">
                        <strong>Please note:</strong> {getLabelText()}
                    </Alert>
                </Col>
            </FormRow>
            {showSignBlock && (
                <SignBlock
                    formik={formik}
                    isAcknowledge={false}
                    isMedia={false}
                    showNameFields={showNameFields}
                />
            )}
            {showSignBlock && showDeclineBlock && (
                <FormRow sx={{ m: 0 }}>
                    <Col>
                        <Text as="p" sx={{ textAlign: 'center' }}>
                            or
                        </Text>
                    </Col>
                </FormRow>
            )}
            {showDeclineBlock && (
                <>
                    {!showSignBlock && (
                        // If we're only showing the decline block (document was previously signed), show
                        // a checkbox to ensure the user realizes they're now declining
                        <FormRow>
                            <Col>
                                <FormikFields.CheckboxField
                                    name="agree"
                                    items={[
                                        {
                                            label: clickDeclineLabel,
                                            value: 'true',
                                            required: true,
                                        },
                                    ]}
                                />
                            </Col>
                        </FormRow>
                    )}
                    <FormRow>
                        <Col>
                            <ButtonsCard>
                                <Button
                                    type={'button'}
                                    sx={{ width: ['100%', 'auto'] }}
                                    onClick={onDecline}
                                    disabled={disableDeclineButton}
                                >
                                    Decline and continue
                                </Button>
                            </ButtonsCard>
                        </Col>
                    </FormRow>
                </>
            )}
        </>
    );
};

export interface SignAndAcknowledgeBlockProps {
    onAcknowledge: (values: SignAndAcknowledgeValues) => void;
    onDecline?: () => void;
    showNameFields: boolean;
    documentRequirement: DocumentRequirementType;
    isToggleable: boolean;
    existingWorkerDocument: TaskWorkerDocumentType | null;
}

const SignAndAcknowledgeBlock = ({
    onAcknowledge,
    onDecline,
    showNameFields,
    documentRequirement,
    isToggleable,
    existingWorkerDocument,
}: SignAndAcknowledgeBlockProps): JSX.Element => {
    const { worker } = useWorker();
    const [showConfirmLegalNameModal, setShowConfirmLegalNameModal] = useState<boolean>(false);
    const onSubmit = (values: SignAndAcknowledgeValues): void => {
        const workerLegalName = getWorkerFullName(worker!, false);
        const formLegalName = getFullName(values.firstName, undefined, values.lastName);
        if (workerLegalName !== formLegalName) {
            setShowConfirmLegalNameModal(true);
        } else {
            onAcknowledge(values);
        }
    };

    return (
        <Formik
            initialValues={{
                firstName: worker?.firstName || '',
                lastName: worker?.lastName || '',
                currentDate: dayjs().format('MMMM DD, YYYY'),
                agree: [] as boolean[],
            }}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <Form>
                    {documentRequirement === 'SIGN_OR_DECLINE' ? (
                        <SignOrDeclineBlock
                            formik={formik}
                            onDecline={onDecline}
                            showNameFields={showNameFields}
                            isToggleable={isToggleable}
                            existingWorkerDocument={existingWorkerDocument}
                        />
                    ) : (
                        <SignBlock
                            formik={formik}
                            isAcknowledge={documentRequirement === 'ACKNOWLEDGE'}
                            isMedia={documentRequirement === 'MEDIA'}
                            showNameFields={showNameFields}
                        />
                    )}
                    <ConfirmLegalNameModal
                        show={showConfirmLegalNameModal}
                        setShow={setShowConfirmLegalNameModal}
                        onConfirmLegalName={() => {
                            setShowConfirmLegalNameModal(false);
                            onAcknowledge(formik.values);
                        }}
                        formik={formik}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default SignAndAcknowledgeBlock;

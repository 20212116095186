import React from 'react';
import { Check, Error as ErrorIcon, Hourglass, Pending } from '@indeed/ifl-icons';
import { Tag, TagProps, TagStatus } from '@indeed/ifl-components';
import { assertNever } from 'client/utils/typescript';
import { capitalizePhrase } from 'client/utils/transforms';
import { getI9Metadata, isReverifyRequired } from 'client/utils/task';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';
type TaskTypeProps = TagProps & {
    taskStatus: TaskStatus;
    externalStatus?: string | null;
};

type DefaultTagProps = TaskTypeProps & {
    completedDate: object;
};

type BackgroundCheckTagProps = TaskTypeProps;

const BackgroundCheckTag = ({
    taskStatus,
    ...rest
}: BackgroundCheckTagProps): JSX.Element | null => {
    switch (taskStatus) {
        case 'COMPLETED':
        case 'SUBMITTED':
            return (
                <Tag status="info" {...rest}>
                    Processing
                </Tag>
            );
        case 'NEEDS_REVIEW':
        case 'REJECTED':
            return (
                <Tag status="critical" {...rest}>
                    Ineligible
                </Tag>
            );
        case 'APPROVED':
            return (
                <Tag status="success" {...rest}>
                    Eligible
                </Tag>
            );
        case 'EXPIRED':
            return (
                <Tag status="critical" {...rest}>
                    Expired
                </Tag>
            );
        default:
            return null;
    }
};

type FormI9TagProps = TaskTypeProps & {
    formI9: FormI9Type;
};

const FormI9Tag = ({ taskStatus, formI9, ...rest }: FormI9TagProps): JSX.Element | null => {
    switch (taskStatus) {
        case 'COMPLETED': {
            if (formI9.section2InviteSent && !formI9.section2Complete) {
                return (
                    <Tag status="info" {...rest}>
                        Waiting on authorized representative
                    </Tag>
                );
            } else if (!formI9.section2Complete) {
                return (
                    <Tag status="info" {...rest}>
                        Section 2 incomplete
                    </Tag>
                );
            }
            return (
                <Tag status="info" {...rest}>
                    Processing
                </Tag>
            );
        }
        case 'SUBMITTED': {
            return (
                <Tag status="info" {...rest}>
                    Processing
                </Tag>
            );
        }
        case 'APPROVED': {
            return (
                <Tag status="success" {...rest}>
                    Completed
                </Tag>
            );
        }
        case 'REJECTED':
        case 'NEEDS_REVIEW': {
            return (
                <Tag status="critical" {...rest}>
                    Needs attention
                </Tag>
            );
        }
        default:
            return null;
    }
};

export const BasicTag = ({
    taskStatus,
    externalStatus,
    ...rest
}: TaskTypeProps): JSX.Element | null => {
    let isValid = true;
    let tagStatus: TagStatus = 'warning';
    let customStatus = '';
    switch (taskStatus) {
        case 'APPROVED':
        case 'FLEX_APPROVED':
        case 'COMPLETED':
            if (isReverifyRequired(externalStatus)) {
                customStatus = 'Reverify required';
                break;
            }
            tagStatus = 'success';
            break;
        case 'SUBMITTED':
            tagStatus = 'neutral';
            break;
        case 'NEEDS_REVIEW':
            tagStatus = 'warning';
            break;
        case 'NOT_COMPLETED':
        case 'REJECTED':
            tagStatus = 'critical';
            break;
        case 'DISABLED':
        case 'EXPIRED':
        case 'EXPIRING':
            break;
        default:
            isValid = false;
            assertNever(taskStatus);
            reportErrorToThirdPartyTools(
                new Error(`Invalid task type "${taskStatus}"`),
                'DefaultStatus',
                'onRender'
            );
    }
    return (
        <Tag status={tagStatus} {...rest}>
            {isValid ? capitalizePhrase(customStatus || taskStatus) : 'Error'}
        </Tag>
    );
};

/**
 * Realistically DISABLED, NEEDS_REVIEW, NOT_COMPLETED, APPROVED are the only actual case of these listed that will happen
 * https://wiki.indeed.com/display/TACO/Task+Status+Definitions#TaskStatusDefinitions-Payroll
 *
 * If we get to this point with DISABLED, we've met the race condition where the user has
 * completed everything but the payroll service isn't yet ready to process the worker.
 * So we wait until the status is updated off of DISABLED to continue.
 */
const PayrollTag = ({ taskStatus, ...rest }: TaskTypeProps): JSX.Element | null => {
    switch (taskStatus) {
        case 'DISABLED':
        case 'NEEDS_REVIEW':
            // should always have bgc and sometimes have i9 but just to be safe we'll default to "Pending"
            return (
                <Tag status="neutral" {...rest}>
                    Pending
                </Tag>
            );
        case 'APPROVED':
            return (
                <Tag status="success" {...rest}>
                    Completed
                </Tag>
            );
        default:
            return null;
    }
};

const DefaultTag = ({
    taskStatus,
    completedDate,
    ...rest
}: DefaultTagProps): JSX.Element | null => {
    if (taskStatus === 'COMPLETED' || !!completedDate) {
        return (
            <Tag status="success" {...rest}>
                Completed
            </Tag>
        );
    }
    return null;
};

export const OnboardedStatusTag = ({ task }: { task: ComplianceTask }): JSX.Element => {
    switch (task.status) {
        case 'COMPLETED':
            return (
                <Tag status="success">
                    <Check />
                    Completed
                </Tag>
            );
        case 'REJECTED':
            return (
                <Tag status="critical">
                    <ErrorIcon />
                    Rejected
                </Tag>
            );
        case 'NEEDS_REVIEW':
            return (
                <Tag status="warning">
                    <ErrorIcon />
                    Needs review
                </Tag>
            );
        default:
            const { actor } = task.metadata;
            if (actor === 'AGENT') {
                return (
                    <Tag status="info">
                        <Pending />
                        Processing
                    </Tag>
                );
            }
            return (
                <Tag status="neutral">
                    <Hourglass />
                    Not started yet
                </Tag>
            );
    }
};

interface StatusTagProps extends TagProps {
    task: ComplianceTask;
}

/**
 * Regardless of type, we show tags based on disabled reason
 * If not disabled, get tags specific to task type
 */
const StatusTag = ({ task, ...rest }: StatusTagProps): JSX.Element | null => {
    const disabledReason = useTaskDisabledReason(task);
    switch (disabledReason) {
        case 'INCOMPLETE_CJO':
            return (
                <Tag status="neutral" {...rest}>
                    Requires conditional job offer
                </Tag>
            );
        case 'INCOMPLETE_BGC':
            return (
                <Tag status="neutral" {...rest}>
                    Requires background check
                </Tag>
            );
        case 'INCOMPLETE_PROFILE':
            return (
                <Tag status="neutral" {...rest}>
                    Requires profile
                </Tag>
            );
        case 'INCOMPLETE_BANK_DETAILS':
            return (
                <Tag status="neutral" {...rest}>
                    Requires bank details
                </Tag>
            );
        case 'INCOMPLETE_DOCUMENTS':
            return (
                <Tag status="neutral" {...rest}>
                    Requires additional documents
                </Tag>
            );

        default:
            switch (task.type) {
                case 'BACKGROUND_CHECK':
                    return <BackgroundCheckTag taskStatus={task.status} {...rest} />;
                case 'FORM_I9':
                    const i9Task = getI9Metadata(task)!;
                    if (i9Task.provider === 'WORKBRIGHT') {
                        return (
                            <BasicTag
                                taskStatus={task.status}
                                externalStatus={task.externalStatus}
                                {...rest}
                            />
                        );
                    } else {
                        return <FormI9Tag taskStatus={task.status} formI9={i9Task} {...rest} />;
                    }
                case 'LOCATION_REQUIREMENTS':
                case 'MEDIA_REQUIREMENTS':
                case 'CONDITIONAL_JOB_OFFER':
                    return (
                        <DefaultTag
                            taskStatus={task.status}
                            completedDate={task.completedDate}
                            {...rest}
                        />
                    );
                case 'PAYROLL':
                    return <PayrollTag taskStatus={task.status} {...rest} />;
                case 'FEDERAL_TAX_ELECTIONS':
                    return (
                        <DefaultTag
                            taskStatus={task.status}
                            completedDate={task.completedDate}
                            {...rest}
                        />
                    );
                case 'ONBOARDED':
                    return <OnboardedStatusTag task={task} />;
            }
            return null;
    }
};

export default StatusTag;

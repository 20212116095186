import React from 'react';
import {
    Button,
    ButtonSet,
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from '@indeed/ifl-components';
import { DialogFooterWithStyleProps, ModalProps } from './types';

const DialogFooterWithStyle: React.FC<DialogFooterWithStyleProps> = DialogFooter;

export const ModalHeader = DialogHeader;
export const ModalBody = DialogBody;
export const ModalFooter = (props: DialogFooterWithStyleProps): JSX.Element => (
    <DialogFooterWithStyle {...props} sx={{ gap: 2, ...props.sx }} />
);

export const Modal = ({
    isOpen,
    children,
    title,
    onExit,
    size,
    fullScreen,
    portaled,
    mobile,
    footerButtons,
    ariaLabel,
}: ModalProps): JSX.Element => {
    const onOpenHandler = (open: boolean): void => {
        if (!open && onExit) {
            onExit();
        }
    };

    return (
        <Dialog
            onOpenChange={onOpenHandler}
            open={isOpen}
            size={size}
            fullScreen={fullScreen}
            portaled={portaled}
            mobile={mobile && 'auto'}
        >
            <DialogContent aria-label={ariaLabel}>
                {title && <DialogHeader>{title}</DialogHeader>}
                {children}
                {footerButtons && (
                    <ModalFooter>
                        <ButtonSet sx={{ flexDirection: mobile ? 'column' : 'row' }}>
                            {footerButtons.map(({ label, props: footerProps }, idx: number) => (
                                <Button
                                    key={`${label}-${idx}`}
                                    full={mobile === true}
                                    {...footerProps}
                                >
                                    {label}
                                </Button>
                            ))}
                        </ButtonSet>
                    </ModalFooter>
                )}
            </DialogContent>
        </Dialog>
    );
};

import React from 'react';
import { Check, EditSection, Error, Warning } from '@indeed/ifl-icons';
import { isReverifyRequired } from 'client/utils/task';

const ItemProgressIcon = ({
    status,
    externalStatus,
}: {
    status: TaskStatus;
    externalStatus?: string | null;
}): JSX.Element => {
    switch (status) {
        case 'EXPIRING':
            return (
                <Warning color="foreground.status.warning" variant="stroke" aria-label="Expiring" />
            );
        case 'EXPIRED':
            return (
                <Error color="foreground.status.critical" variant="stroke" aria-label="Expired" />
            );

        case 'COMPLETED':
            if (isReverifyRequired(externalStatus)) {
                return <EditSection color="foreground.primary" aria-label="Not completed" />;
            }
            return <Check color="foreground.status.success" aria-label="Completed" />;
        default:
            return <EditSection color="foreground.primary" aria-label="Not completed" />;
    }
};

export default ItemProgressIcon;

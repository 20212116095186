import Col from 'client/components/Col';
import React from 'react';
import Row from 'client/components/Row';
import { Flex, Heading, Text } from '@indeed/ifl-components';

import ComplianceContainer from 'client/components/ComplianceContainer';
import Image from 'client/components/Image';
import Page from 'client/components/Page';

export enum I9StatusCodes {
    DUP_ACCOUNT = 1000,
    INVALID_PROVIDER = 1001,
}

export interface AccountErrorPageProps {
    user: ComplianceUser | null;
    worker: ComplianceWorker | null;
    errorCode: number | null;
    showHeader?: boolean;
    showFooter?: boolean;
}

const AccountErrorPage = ({
    user,
    worker,
    errorCode = I9StatusCodes.DUP_ACCOUNT,
    showHeader = true,
    showFooter = false,
}: AccountErrorPageProps): JSX.Element => {
    const helpEmail = 'help@indeedflex.com';

    return (
        <Page title="Account Error" showHeader={showHeader} showFooter={showFooter}>
            <ComplianceContainer size="md">
                <Row sx={{ marginBlockEnd: '3rem' }}>
                    <Col>
                        <Flex sx={{ justifyContent: 'center' }}>
                            <Image path="alert.svg" alt="alert" width="164px" />
                        </Flex>
                    </Col>
                </Row>
                <Row sx={{ marginBlockEnd: '2rem' }}>
                    <Col>
                        <Flex sx={{ justifyContent: 'center' }}>
                            <Heading sx={{ textAlign: 'center' }} className="fs-exclude" level={5}>
                                {worker?.firstName
                                    ? `Hi ${worker.firstName}, there seems to be an issue with your account`
                                    : user?.email
                                    ? `Hi ${user.email}, there seems to be an issue with your account`
                                    : 'There seems to be an issue with your account'}
                            </Heading>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col sx={{ textAlign: 'center' }}>
                        <Text level={3}>Don't worry, this can easily be fixed.</Text>
                    </Col>
                </Row>
                <Row>
                    <Col sx={{ textAlign: 'center' }}>
                        <Text level={3}>
                            Please contact Customer Support and quote the following
                        </Text>
                    </Col>
                </Row>
                <Row sx={{ marginBlockEnd: '2rem' }}>
                    <Col sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        <Text level={3}>Reference code: {errorCode}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        <Text level={3}>Customer Support</Text>
                    </Col>
                </Row>
                <Row>
                    <Col sx={{ textAlign: 'center' }}>
                        <a href={`mailto:${helpEmail}`}>{helpEmail}</a>
                    </Col>
                </Row>
            </ComplianceContainer>
        </Page>
    );
};

export default AccountErrorPage;

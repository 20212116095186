import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { wcwLocalPreferences } from 'client/WebappLocalPreferences';

type UseLogRenderDatadogActionOptions = {
    /**
     * This is the action to log to datadog and must be a value of DatadogAction defined in client/utils/logging
     */
    action: (typeof DatadogAction)[keyof typeof DatadogAction];
    /**
     * An arbitrary context object accepted by datadogRum.addAction
     */
    context?: object;
};
/**
 * This hook can be utilized within a component to log whatever parameters are passed to it when the component is mounted
 * If you would rather you could just wrap your component in `LogRenderDatadogAction`
 * @param options see type docs
 */
export const useLogRenderDatadogAction = ({
    action,
    context,
}: UseLogRenderDatadogActionOptions): void => {
    useEffect(() => {
        if (wcwLocalPreferences.consoleLogDatadogActions) {
            /* eslint-disable-next-line no-console */
            console.log('LogRenderDatadogActionProps', { action, context });
        }
        datadogRum.addAction(action, context);
    }, []);
};

type LogRenderDatadogActionProps = React.PropsWithChildren<UseLogRenderDatadogActionOptions>;
/**
 * This is a component that utilizes `useLogRenderDatadogAction` (you could just use the hook directly if you prefer).
 * When the component is mounted it will pass the action and context to `datadogRum.addAction`
 * @param props see type docs
 * @returns A fragment with the given children
 */
export const LogRenderDatadogAction = ({
    action,
    context,
    children,
}: LogRenderDatadogActionProps): JSX.Element => {
    useLogRenderDatadogAction({
        action,
        context,
    });
    return <>{children}</>;
};

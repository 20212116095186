import Container, { ContainerProps } from '../Container';
import React, { PropsWithChildren } from 'react';

export type ComplianceContainerProps = ContainerProps & {
    size?: 'sm' | 'md';
};

const ComplianceContainer = ({
    size = 'md',
    sx,
    ...rest
}: PropsWithChildren<ComplianceContainerProps>): JSX.Element => {
    const maxWidth = size === 'md' ? '768px' : '343px';
    return <Container sx={{ maxWidth, ...sx }} {...rest} />;
};

export default ComplianceContainer;

/* eslint-disable @typescript-eslint/naming-convention */
import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@indeed/ifl-components';

export type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

const sizeMap: Record<Size, string> = {
    1: '8.33333%',
    2: '16.66667%',
    3: '25%',
    4: '33.33333%',
    5: '41.66667%',
    6: '50%',
    7: '58.33333%',
    8: '66.66667%',
    9: '75%',
    10: '83.33333%',
    11: '91.66667%',
    12: '100%',
};

type ColResponsiveStyleObject = {
    flex: string | (string | null)[];
    maxInlineSize: string | (string | null)[];
};

export const getResponsiveSx = (sizes: (Size | null)[]): ColResponsiveStyleObject => {
    if (!sizes.length) {
        return {
            maxInlineSize: '100%',
            flex: '0 0 100%',
        };
    }

    const sxs = sizes.map((size) => {
        if (size === null) {
            return { flex: null, maxInlineSize: null };
        }

        const percentage = sizeMap[size];
        return {
            flex: `0 0 ${percentage}`,
            maxInlineSize: percentage,
        };
    });

    return {
        flex: sxs.map((sx) => sx.flex),
        maxInlineSize: sxs.map((sx) => sx.maxInlineSize),
    };
};

export type ColProps = PropsWithChildren<Omit<BoxProps, 'as'>> & {
    size?: (Size | null)[] | Size;
};

const Col = ({ sx = {}, size = [], ...rest }: ColProps): JSX.Element => {
    const responsiveSx = getResponsiveSx(Array.isArray(size) ? size : [size]);

    return (
        <Box
            sx={{
                position: 'relative',
                paddingBlock: 0,
                paddingInline: 4,
                inlineSize: '100%',
                ...responsiveSx,
                ...sx,
            }}
            {...rest}
        />
    );
};

export default Col;

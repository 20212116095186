/**
 * `dayjs` suggests extending its functionality via plugins everytime it needs to be
 * used. This causes code-repetition. To counter it, this file is made so that there
 * is just one location where dayjs is extended, and it can be reused everywhere else
 * in the codebase.
 */

import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @param year
 * @param month - 0 indexed (January = 0)
 * @param day
 */
export const getDate = (year: string, month: string, day: string): Dayjs =>
    dayjs(new Date(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10)));

/**
 * Format with day of week, full month, full year
 * e.g. Sunday, July 4, 2021
 */
export const formatDateWithDayOfWeek = (input: Dayjs | string): string => {
    const datetime = typeof input === 'string' ? dayjs(input) : input;
    return (datetime as Dayjs).format('dddd, MMMM D, YYYY');
};

export const isEighteenYearsOrOlder = (dateOfBirth: Dayjs | string): boolean => {
    const date = typeof dateOfBirth === 'string' ? dayjs(dateOfBirth) : dateOfBirth;
    return dayjs().diff(date, 'year') >= 18;
};

export const isOneHundredYearsOrYounger = (dateOfBirth: Dayjs | string): boolean => {
    const date = typeof dateOfBirth === 'string' ? dayjs(dateOfBirth) : dateOfBirth;
    return dayjs().diff(date, 'year') <= 100;
};

export const isExpired = (expirationDate: Dayjs | string): boolean => {
    const date = typeof expirationDate === 'string' ? dayjs(expirationDate) : expirationDate;
    const now = dayjs();
    return date ? date.isBefore(now) : false;
};

/**
 * A generic date formatter helper function, formatting as DD MMM YYY by default (e.g 31 Dec 2021)
 * @param date
 * @param format
 * @returns Formatted date value or N/A if date is not valid or missing
 */
export const getFormattedDate = (
    date: string | null | undefined,
    format: string = 'DD MMM YYYY'
): string => {
    if (date && dayjs(date).isValid()) {
        return dayjs(date).format(format);
    }
    return 'N/A';
};

export default dayjs;

import React, { useEffect, useId, useState } from 'react';
import usePlacesAutocomplete, {
    GetDetailsArgs,
    RequestOptions,
    getDetails,
} from 'use-places-autocomplete';
import { AddressDetail, AddressType, GeometryDetail, mapAddress } from 'client/utils/google';
import { FormikProps, FormikValues } from 'formik';
import { Label, Option, SuggestList, Text } from '@indeed/ifl-components';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { v4 as uuidv4 } from 'uuid';

export interface AddressFieldProps {
    formik: FormikProps<unknown> | FormikValues;
    name: string;
    label: string;
    country?: SupportedCountryCode;
    helperText?: string;
    onAddressSuggestionSelected?: (address: string) => void;
}

const getAutoCompleteRequestOptions = (
    sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
    country?: SupportedCountryCode
): RequestOptions => {
    const options: RequestOptions = {
        sessionToken,
        types: ['address'],
    };
    if (country) {
        options.componentRestrictions = {
            country,
        };
    }
    return options;
};

const createNewSessionToken = (): google.maps.places.AutocompleteSessionToken | undefined =>
    !!window.google ? new google.maps.places.AutocompleteSessionToken(uuidv4()) : undefined;

const AddressField = ({
    formik,
    name,
    label,
    helperText,
    country,
    onAddressSuggestionSelected,
}: AddressFieldProps): JSX.Element => {
    const [sessionToken, setSessionToken] = React.useState(createNewSessionToken());
    const addressAutoComplete = usePlacesAutocomplete({
        debounce: 300,
        requestOptions: getAutoCompleteRequestOptions(sessionToken, country),
    });

    const [currentAddress, setCurrentAddress] = useState<AddressType | null>(null);
    useEffect(() => {
        const revalidateForm = async (address: AddressType): Promise<void> => {
            await formik.validateForm();
            Object.keys(address).forEach((key) => {
                if (key in formik.initialValues) {
                    formik.setFieldTouched(key);
                }
            });
        };
        // trigger formik validation on address change
        // otherwise the form gets out of sync since we are manually setting fields
        if (currentAddress !== null) {
            revalidateForm(currentAddress).catch((err) => {
                // this shouldn't really happen but catch errors just in case
                /* eslint-disable-next-line no-console */
                console.log('error revalidating form', err);
            });
        }
    }, [currentAddress, formik.validateForm]);

    const handleSelectPlace = async (placeId: string): Promise<void> => {
        const place = addressAutoComplete.suggestions.data.find(
            (suggestion) => suggestion.place_id === placeId
        );

        if (!place?.place_id) {
            return;
        }

        const args: GetDetailsArgs = {
            fields: ['address_components', 'geometry'],
            sessionToken,
            placeId: place.place_id,
        };

        onAddressSuggestionSelected?.(place.description);

        const addressDetailsResponse = await getDetails(args);

        if (
            !addressDetailsResponse ||
            !(addressDetailsResponse as google.maps.places.PlaceResult).address_components
        ) {
            setSessionToken(createNewSessionToken());
            addressAutoComplete.clearSuggestions();
            reportErrorToThirdPartyTools(
                new Error(
                    `Failed to get place details from google places api with place id ${placeId}`
                ),
                'AddressField',
                'handleSelectPlace'
            );
            return;
        }
        const addressDetails: AddressDetail[] = (
            addressDetailsResponse as google.maps.places.PlaceResult
        ).address_components;

        const geometryDetails: GeometryDetail = (
            addressDetailsResponse as google.maps.places.PlaceResult
        ).geometry;

        const address = mapAddress(addressDetails);

        // Update formik values with address details
        Object.keys(address).forEach((key) => {
            if (address[key] && key in formik.initialValues) {
                formik.setFieldValue(key, address[key]);
            }
        });

        if (geometryDetails?.location) {
            formik.setFieldValue('lat', geometryDetails.location.lat());
            formik.setFieldValue('lon', geometryDetails.location.lng());
        }
        setSessionToken(createNewSessionToken());
        addressAutoComplete.clearSuggestions();
        setCurrentAddress(address);
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = (event.target as HTMLInputElement).value;
        addressAutoComplete.setValue(value);
        formik.setFieldValue(name, value);
    };

    const id = useId();

    const inputId = `address-input-${id}`;

    const hasValues =
        addressAutoComplete.suggestions.status === 'OK' &&
        addressAutoComplete.suggestions.data?.length > 0;

    return (
        <>
            <Label size="md" htmlFor={inputId} showRequiredIndicator>
                {label}
            </Label>
            {helperText ? (
                <Text level={2} sx={{ marginBlockEnd: 1, display: 'inline-block' }}>
                    {helperText}
                </Text>
            ) : null}
            <SuggestList
                id={inputId}
                onChange={handleInput}
                onOptionChoice={handleSelectPlace}
                value={formik.values[name]}
                autoComplete="new-password"
                required={true}
                className="dd-privacy-hidden"
                aria-label={label}
                name={name}
                hideToggleButton={!hasValues}
            >
                {addressAutoComplete.suggestions.data.map((suggestion) => (
                    <Option key={suggestion.place_id} value={suggestion.place_id}>
                        {suggestion.description}
                    </Option>
                ))}
            </SuggestList>
        </>
    );
};

export default AddressField;

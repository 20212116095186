import Col from 'client/components/Col';
import FormRow from 'client/components/FormRow';
import NameField from 'client/components/NameField';
import React from 'react';
import { Alert, AlertTitle, Button } from '@indeed/ifl-components';
import { CalendarField } from 'client/components/FormikFields/CalendarField/CalendarField';
import { Form, FormikProps } from 'formik';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { UpdateWorkerValues } from 'client/pages/i9/WorkBrightI9/UpdateWorkerModal/formConstants';
import { UsSsnDuplicatedAlert } from 'client/components/UsSsnFields/UsSsnDuplicatedAlert/UsSsnDuplicatedAlert';
import { UsSsnFields } from 'client/components/UsSsnFields/UsSsnFields';

export interface UpdateWorkerFormProps {
    submitting: boolean;
    formik: FormikProps<UpdateWorkerValues>;
    needsName: boolean;
    needsDob: boolean;
    needsSsn: boolean;
}

const SsnAppliedForWarning = (): JSX.Element => {
    return (
        <Alert status="warning">
            <AlertTitle>Your Social Security application</AlertTitle>
            We will need you to provide your Social Security Number as soon as you receive it.
        </Alert>
    );
};
const UpdateWorkerForm = ({
    submitting,
    formik,
    needsName,
    needsDob,
    needsSsn,
}: UpdateWorkerFormProps): JSX.Element => {
    return (
        <>
            <Form>
                <UsSsnDuplicatedAlert formik={formik} />
                {needsName && <NameField formik={formik} country={SupportedCountryCode.US} />}
                {needsDob && (
                    <FormRow>
                        <Col>
                            <CalendarField label="Date of birth" name="dateOfBirth" />
                        </Col>
                    </FormRow>
                )}
                {needsSsn && (
                    <UsSsnFields formik={formik}>
                        <SsnAppliedForWarning />
                    </UsSsnFields>
                )}
                <FormRow>
                    <Col>
                        <Button
                            type={'submit'}
                            loading={submitting || formik.isSubmitting}
                            disabled={!formik.isValid || (!formik.validateOnMount && !formik.dirty)}
                        >
                            Submit
                        </Button>
                    </Col>
                </FormRow>
            </Form>
        </>
    );
};

export default UpdateWorkerForm;

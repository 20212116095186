import * as FormikFields from 'client/components/FormikFields';
import AddressFields from 'client/pages/profile/components/AddressFields/AddressFields';
import Col from 'client/components/Col';
import FormRow from 'client/components/FormRow';
import GoogleAddressValidationClient from 'client/utils/googleAddressValidationClient';
import NameField from 'client/components/NameField';
import PhoneNumberField from 'client/components/PhoneNumberField';
import React, { useState } from 'react';
import useGetLatLong from 'client/hooks/useGetLatLong';
import { Alert, AlertTitle, Box, Button, Divider, Label } from '@indeed/ifl-components';
import { CalendarField } from 'client/components/FormikFields/CalendarField/CalendarField';
import { FormikProps } from 'formik';
import { ProfileFormValues } from 'client/pages/profile/components/Form/formConstants';
import { ProfileTaskTrackingEvents } from 'client/pages/profile/Profile.tracking';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { UsSsnFields } from 'client/components/UsSsnFields/UsSsnFields';
import { datadogRum } from '@datadog/browser-rum';
import { getGenderOptions, legalGenderMap } from 'client/utils/options';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { scrollToFieldByName } from 'client/utils/form';
import { useClientConfig } from 'client/contexts/ClientConfigContext';
import { useHistory } from 'react-router-dom';

export interface FormProps {
    formik: FormikProps<ProfileFormValues>;
    baseUrl: string;
    country: SupportedCountryCode;
}

const SsnAppliedForWarning = (): JSX.Element => {
    return (
        <Alert status="warning">
            <AlertTitle>Your Social Security application</AlertTitle>
            We will need you to provide your Social Security Number as soon as you receive it.
        </Alert>
    );
};

const Form = ({ formik, baseUrl, country }: FormProps): JSX.Element => {
    const history = useHistory();
    const { googleAddressValidationApiKey, googleAddressValidationUrl } = useClientConfig();
    const genderOptions =
        country === SupportedCountryCode.GB ? getGenderOptions(legalGenderMap) : getGenderOptions();
    const getLatLongForAddress = useGetLatLong();
    const [validating, setValidating] = useState(false);

    /**
     * Retrieve lat long values for worker address and set them on the input object
     * Redirects to form in case of an error
     * @param input UpdateWorkerInput containing address fields
     * @param formikHelpers FormikHelpers to set errors if geocoding fails
     * @returns
     */
    const setLatLongForInput = async (): Promise<boolean> => {
        try {
            const { address1, city, postalCode } = formik.values;
            const { lat, lng } = await getLatLongForAddress(`${address1}, ${city}, ${postalCode}`);
            await formik.setFieldValue('lat', lat);
            await formik.setFieldValue('lon', lng);
            return true;
        } catch (err) {
            formik.setErrors({
                address1: 'Please make sure your address, town and postcode are correct',
            });
            return false;
        }
    };

    const validateAddress = async (): Promise<boolean> => {
        try {
            const { address1, address2, city, postalCode, state } = formik.values;
            const fullAddressLines: string[] = [];
            if (address2) {
                fullAddressLines.push(address1, address2);
            } else {
                fullAddressLines.push(address1);
            }
            const validAddressFromGoogle = await new GoogleAddressValidationClient({
                googleAddressValidationUrl,
                googleAddressValidationApiKey,
            }).validateAddress({
                address: {
                    regionCode: country,
                    administrativeArea: state,
                    locality: city,
                    addressLines: fullAddressLines,
                    postalCode: postalCode,
                },
            });
            if (!validAddressFromGoogle.valid && validAddressFromGoogle.suggestedAddress) {
                await formik.setFieldValue(
                    'suggestedAddress1',
                    validAddressFromGoogle.suggestedAddress.address1
                );
                await formik.setFieldValue(
                    'suggestedAddress2',
                    validAddressFromGoogle.suggestedAddress.address2
                );
                await formik.setFieldValue(
                    'suggestedState',
                    validAddressFromGoogle.suggestedAddress.state
                );
                await formik.setFieldValue(
                    'suggestedCity',
                    validAddressFromGoogle.suggestedAddress.city
                );
                await formik.setFieldValue(
                    'suggestedPostalCode',
                    validAddressFromGoogle.suggestedAddress.postalCode
                );
                return false;
            }
            return true;
        } catch (err) {
            reportErrorToThirdPartyTools(err, 'profilePage', 'onSuggestedAddressUpdate');
            // just returning true to bypass the confirm-address flow
            return true;
        }
    };

    const onAccept = async (): Promise<void> => {
        const { lat, lon, manualAddressEdit } = formik.values;
        if (lat === 0.0 || lon === 0.0 || manualAddressEdit) {
            setValidating(true);
            const isValidAddress = await setLatLongForInput();
            setValidating(false);
            if (!isValidAddress) {
                scrollToFieldByName('address1');
                return;
            }
        }
        datadogRum.addAction(ProfileTaskTrackingEvents.acceptBtnClicked);
        if (country === SupportedCountryCode.US) {
            try {
                setValidating(true);
                const googleAddressValid = await validateAddress();
                setValidating(false);
                if (!googleAddressValid) {
                    history.push({
                        pathname: `${baseUrl}/confirm-address`,
                        search: window.location.search,
                    });
                    return;
                }
            } catch (error) {
                reportErrorToThirdPartyTools(error, 'profilePage', 'googleValidateAddress');
            }
        }
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    };

    const onEditAddressManuallyClick = (isEditingManually: boolean): void => {
        if (isEditingManually) {
            datadogRum.addAction(ProfileTaskTrackingEvents.useAddressSuggestionsClicked);
        } else {
            datadogRum.addAction(ProfileTaskTrackingEvents.manualAddressEditClicked);
        }
    };

    const onAddressSuggestionSelected = (): void => {
        datadogRum.addAction(ProfileTaskTrackingEvents.addressSuggestionClicked);
    };
    return (
        <>
            <Box>
                <NameField formik={formik} country={country} />
                <FormRow>
                    <Col>
                        <FormikFields.RadioField
                            label="Gender"
                            name={country === SupportedCountryCode.GB ? 'legalGender' : 'gender'}
                            items={genderOptions}
                            helperText={
                                country === SupportedCountryCode.GB
                                    ? 'The selection of a male or female gender is required for the calculation of your national insurance contributions.'
                                    : undefined
                            }
                            radioProps={{
                                showRequiredIndicator: true,
                                className: 'dd-privacy-hidden',
                            }}
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <CalendarField label="Date of birth" name="dateOfBirth" />
                    </Col>
                </FormRow>
                <Divider sx={{ my: '1.5rem' }} />
                <FormRow>
                    <Col>
                        <PhoneNumberField formik={formik} label="Phone number (optional)" />
                    </Col>
                </FormRow>
                <Divider sx={{ my: '1.5rem' }} />
                <AddressFields
                    formik={formik}
                    country={country}
                    onEditManuallyClick={onEditAddressManuallyClick}
                    onAddressSuggestionSelected={onAddressSuggestionSelected}
                />
            </Box>
            {country === SupportedCountryCode.GB ? (
                <Box>
                    <Divider sx={{ my: '1.5rem' }} />
                    <Label
                        size="md"
                        htmlFor="optOut48hrs"
                        aria-label="Opt out 48 hours"
                        sx={{ marginBlockEnd: 2 }}
                    >
                        Opt-out of the 48 hours work week?
                    </Label>
                    <Label
                        size="sm"
                        htmlFor="optOut48hrs"
                        aria-label="Opt out 48 hours"
                        sx={{ marginBlockEnd: 2 }}
                    >
                        We will cap the amount of work you can book to 48 hours per week under the
                        Working Time Directive, unless you opt-out below
                    </Label>
                    <FormRow>
                        <Col>
                            <FormikFields.CheckboxField
                                name="optOut48hrs"
                                items={[
                                    {
                                        label: 'I agree that I may work more than 48 hours per week. I will give IndeedFlex 3 months written notice to end this agreement.',
                                        value: 'true',
                                    },
                                ]}
                            />
                        </Col>
                    </FormRow>
                </Box>
            ) : null}

            {country === SupportedCountryCode.US ? (
                <UsSsnFields formik={formik} withConfirmationField>
                    <SsnAppliedForWarning />
                </UsSsnFields>
            ) : null}

            <FormRow>
                <Col>
                    <Button
                        onClick={onAccept}
                        disabled={!formik.isValid || !formik.dirty}
                        loading={validating || formik.isSubmitting}
                        type="button"
                        full
                        sx={{ marginBlockStart: 4 }}
                    >
                        Accept and continue
                    </Button>
                </Col>
            </FormRow>
        </>
    );
};

export default Form;

import {
    CountryCode,
    isPossiblePhoneNumber,
    isValidNumber,
    parsePhoneNumber,
} from 'libphonenumber-js';
import { ObjectSchema, object, string } from 'yup';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';

export const getEmergencyContactFormValidationSchema = (
    worker: ComplianceWorker
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ObjectSchema<any> => {
    return object().shape({
        name: string().trim().required('Full name is required.'),
        relationship: string().trim().required('Relation to you is required.'),
        phoneCountry: string(),
        phone: string()
            .trim()
            .required('Phone number is required.')
            .test(
                'is-valid-phone-length',
                'Phone number must be valid length.',
                (value, context) => {
                    try {
                        const { parent } = context;
                        if (parent.phone && parent.phoneCountry) {
                            return isPossiblePhoneNumber(parent.phone, parent.phoneCountry);
                        }
                        return true;
                    } catch (err) {
                        return false;
                    }
                }
            )
            .test('is-valid-phone-number', 'Must enter a valid phone number.', (value, context) => {
                try {
                    const { parent } = context;
                    if (parent.phone && parent.phoneCountry) {
                        const phoneNumber = parsePhoneNumber(
                            parent.phone,
                            parent.phoneCountry
                        ).formatInternational();
                        return isValidNumber(phoneNumber, parent.phoneCountry);
                    }
                    return true;
                } catch (err) {
                    return false;
                }
            })
            .test(
                'is-not-the-same-as-worker-phone',
                'Phone number can not be your own number.',
                (value, context) => {
                    const { parent } = context;
                    if (!worker.phone) {
                        return true;
                    }
                    try {
                        const contactPhone = parsePhoneNumber(
                            parent.phone,
                            parent.phoneCountry
                        ).number;
                        const workerPhone = parsePhoneNumber(worker.phone).number;
                        return contactPhone !== workerPhone;
                    } catch (err) {
                        return true;
                    }
                }
            ),
    });
};

export interface EmergencyFormValues {
    name: string;
    phoneCountry: CountryCode;
    phone: string;
    relationship: string;
}

export const defaultValues: EmergencyFormValues = {
    name: '',
    phone: '',
    relationship: '',
    phoneCountry: SupportedCountryCode.GB,
};

export const toCompleteEmergencyContactInput = (
    slug: string,
    values: EmergencyFormValues
): CompleteEmergencyContactInput => {
    return {
        slug,
        metadata: {
            emergencyContact: {
                name: values.name,
                phone: parsePhoneNumber(values.phone, values.phoneCountry as CountryCode).number,
                relationship: values.relationship,
            },
        },
    };
};

export const getDefaultValues = (
    country: SupportedCountryCode,
    task: ComplianceTask
): EmergencyFormValues => {
    const data = (task.metadata as EmergencyContactMetadataType).emergencyContact;
    const name = data.name || defaultValues.name;
    let phone = data.phone || defaultValues.phone;
    const relationship = data.relationship || defaultValues.relationship;

    let phoneCountry = defaultValues.phoneCountry;
    try {
        if (phone !== '') {
            const phoneNumber = parsePhoneNumber(phone);
            phone = phoneNumber.formatNational();
            if (phoneNumber.country) {
                phoneCountry = phoneNumber.country;
            }
        }
    } catch (err) {
        /* eslint-disable-next-line no-console */
        console.error(`cannot parse phone country for phone number ${phone}`, err);
    }
    return { name, phone, relationship, phoneCountry };
};

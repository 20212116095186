import { useClientConfig } from 'client/contexts/ClientConfigContext';

const migratedTaskUrls: Record<string, string> = {
    PROFILE: 'profile',
};

export const useRequiredTaskUrl = (
    requiredTask: ComplianceTask
): ((event: React.MouseEvent) => void) | undefined => {
    const { flexAcquisitionUrl } = useClientConfig();

    if (migratedTaskUrls[requiredTask.type]) {
        return (event: React.MouseEvent) => {
            event.preventDefault();
            window.location.href = `${flexAcquisitionUrl}/acquisition/onboardingtasks/${
                migratedTaskUrls[requiredTask.type]
            }/${requiredTask.slug}`;
        };
    }

    return undefined;
};

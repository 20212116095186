import React, { PropsWithChildren } from 'react';
import { BoxProps, Flex } from '@indeed/ifl-components';

export type RowProps = PropsWithChildren<Omit<BoxProps, 'as'>>;

const Row = ({ sx = {}, ...rest }: RowProps): JSX.Element => {
    return (
        <Flex
            sx={{ flexWrap: 'wrap', marginInline: (theme) => `-${theme.space[4]}`, ...sx }}
            {...rest}
        />
    );
};

export default Row;

import React from 'react';
import { Box, Heading, Text } from '@indeed/ifl-components';

import DocumentDownload from 'client/pages/bgc/components/DocumentDownload';
import FormRouter from 'client/pages/bgc/components/FormRouter';
import StatusTag from 'client/components/StatusTag';
import { FlexComplianceUsEmail } from 'client/components/Emails';
import {
    RequiresCjoPageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';
import { getBackgroundCheckMetadata } from 'client/utils/task';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

interface BgcStatusHeadingProps {
    task: ComplianceTask;
}

const BgcStatusHeading = ({ task }: BgcStatusHeadingProps): JSX.Element => {
    return (
        <>
            <Heading level={4}>Background check</Heading>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
        </>
    );
};

interface IneligibleProps {
    task: ComplianceTask;
}

const Ineligible = ({ task }: IneligibleProps): JSX.Element => {
    const bgcMetadata = getBackgroundCheckMetadata(task);
    return (
        <>
            <BgcStatusHeading task={task} />
            <p>
                You have the right to dispute the completeness or accuracy of any information
                contained in the report by contacting the background vendor directly:
            </p>
            <Box sx={{ mt: '1.5rem' }}>
                {bgcMetadata!.provider === 'FIRST_ADVANTAGE' && (
                    <>
                        <div>First Advantage</div>
                        <div>PO Box 105292</div>
                        <div>Atlanta, GA 30348</div>
                        <div>Phone: (800) 845-6004</div>
                    </>
                )}
            </Box>
        </>
    );
};

type ApprovedProps = {
    task: ComplianceTask;
};

const Approved = ({ task }: ApprovedProps): JSX.Element => {
    return (
        <>
            <BgcStatusHeading task={task} />
            <p>
                Your background check was processed on {formatDateWithDayOfWeek(task.processedDate)}
                .
            </p>
            <DocumentDownload task={task} pageTitle={'Documents'} />
        </>
    );
};

type ProcessingProps = {
    task: ComplianceTask;
};

const Processing = ({ task }: ProcessingProps): JSX.Element => {
    return (
        <>
            <BgcStatusHeading task={task} />
            <p>
                You submitted a background check on {formatDateWithDayOfWeek(task.completedDate)}.
                Background checks typically take 1 to 7 days to complete.
            </p>
            <p>
                If you have any questions, please contact <FlexComplianceUsEmail />.
            </p>
            <DocumentDownload task={task} pageTitle={'Documents'} />
        </>
    );
};

const Expired = ({ task }: BackgroundCheckPageContentProps): JSX.Element => {
    return (
        <Box>
            <BgcStatusHeading task={task} />
            <Text as="p">Your background check has expired.</Text>
        </Box>
    );
};

interface BackgroundCheckPageContentProps {
    task: ComplianceTask;
}

const PageContent = ({ task }: BackgroundCheckPageContentProps): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);

    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return (
            <RequiresProfilePageContent
                heading="Background check"
                task={task}
                taskName="background check"
            />
        );
    } else if (disabledReason === 'INCOMPLETE_CJO') {
        return (
            <RequiresCjoPageContent
                heading="Background check"
                task={task}
                taskName="background check"
            />
        );
    }
    switch (task.status) {
        case 'NOT_COMPLETED':
            return <FormRouter task={task} />;
        case 'COMPLETED':
        case 'SUBMITTED':
            return <Processing task={task} />;
        case 'REJECTED':
        case 'NEEDS_REVIEW':
            return <Ineligible task={task} />;
        case 'APPROVED':
            return <Approved task={task} />;
        case 'EXPIRED':
            return <Expired task={task} />;
        default:
            throw Error(`Invalid status for background check: ${task.status}`);
    }
};

export default PageContent;

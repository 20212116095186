import Col from 'client/components/Col';
import FileUpload, { xhrRequestHandler } from '@indeed/ipl-file-upload';
import FormRow from 'client/components/FormRow';
import Image from 'client/components/Image';
import React from 'react';
import { FormField } from '@indeed/ifl-components';

export interface FileUploadFieldProps {
    name: string;
    label?: string;
    helperText?: string;
    required?: boolean;
    imagePath?: string;
    imageAlt?: string;
    url: string;
    inputTitleText?: string;
    inputHelperText?: string;
    cardsRetryText?: string;
    cardsUploadingText?: string;
    cardsSuccessText?: string;
    cardsUnknownErrorText?: string;
    cardsAriaLabels?: { dismissButton: string; cardsList: string };
}
export const FileUploadField = ({
    name,
    label,
    helperText,
    required,
    imagePath,
    imageAlt,
    url,
    inputTitleText,
    inputHelperText,
    cardsRetryText,
    cardsUploadingText,
    cardsSuccessText,
    cardsUnknownErrorText,
    cardsAriaLabels,
}: FileUploadFieldProps): JSX.Element => {
    return (
        <FormField
            name={name}
            label={label}
            helperText={helperText}
            showRequiredIndicator={required}
        >
            <FormRow>
                <Col>
                    {imagePath !== undefined && imageAlt !== undefined && (
                        <Image path={imagePath} alt={imageAlt} width="100%" />
                    )}
                    <FileUpload
                        requestHandler={xhrRequestHandler}
                        requestOptions={{
                            url: url,
                            method: 'POST',
                        }}
                        getCardTitleForStatus={(status, totalFiles) =>
                            `${totalFiles} files ${status}`
                        }
                        labelText=""
                        helperText=""
                        inputTitleText={inputTitleText || 'Select a file'}
                        inputHelperText={inputHelperText || 'Drag and drop here, or'}
                        cardsRetryText={cardsRetryText || 'Retry upload'}
                        cardsUploadingText={cardsUploadingText || 'Uploading...'}
                        cardsSuccessText={cardsSuccessText || 'Upload complete'}
                        cardsUnknownErrorText={
                            cardsUnknownErrorText || 'Error: Descriptive message [reason]'
                        }
                        cardsAriaLabels={
                            cardsAriaLabels || {
                                dismissButton: 'Dismiss the current card',
                                cardsList: 'cards',
                            }
                        }
                    />
                </Col>
            </FormRow>
        </FormField>
    );
};
